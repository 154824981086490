import {
  genericErrorMessage,
  PRODUCT_ID,
  productIdToName,
  SKU,
  CAM_FEATURES,
  CAM_ATTRIBUTES,
} from "consts";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  addDays,
  endOfDay,
  isBefore,
  isEqual,
  differenceInDays,
} from "date-fns";
import { useDispatch } from "react-redux";

import ModalForm from "shared/ModalForm";
import { showAlert } from "slices/alertsSlice";
import styles from "./TenantForm.module.scss";
import useCreateTenantForm from "./useCreateTenantForm";
import useUpdateTenantForm from "./useUpdateTenantForm";
import FormContainer from "./FormContainer";
import { asUTC } from "utils/timezone";

const MAX_NUMBER_OF_DAYS_TO_EXTEND_AT_ONCE = 29;

export const INITIAL_DATE = {
  [PRODUCT_ID.saasAppsAndEndpoints]: addDays(new Date(), 1),
  [PRODUCT_ID.hybridWorkloads]: new Date(),
};

const getRemainingDaysForExtend = (createdOn) =>
  differenceInDays(addDays(createdOn, 60), new Date());

export const getMaxValidExpiryDate = (createdOn, productID) => {
  const remainingDaysToExtend = getRemainingDaysForExtend(createdOn);
  return remainingDaysToExtend > 0
    ? addDays(
        INITIAL_DATE[productID],
        Math.min(MAX_NUMBER_OF_DAYS_TO_EXTEND_AT_ONCE, remainingDaysToExtend),
      )
    : null;
};

const isBeforeOrEqual = (date1, date2) => {
  return isBefore(date1, endOfDay(date2)) || isEqual(date1, endOfDay(date2));
};

export const isExpiryDateValid = (selectedDate, createdOn, productID) => {
  const maxValidExpiryDate = getMaxValidExpiryDate(createdOn, productID);
  return maxValidExpiryDate
    ? isBeforeOrEqual(selectedDate, maxValidExpiryDate)
    : false;
};

const validationSchemaCreator = ({
  Yup,
  productId,
  isPreservedUserCountVisible,
}) => {
  return Yup.object().shape({
    servicePlanID: Yup.mixed().required("Required"),
    storageRegions: Yup.array().min(1, "Required"),
    quota: Yup.number()
      .min(0, "Required non-negative number")
      .max(999999, "The maximum quota allowed is 999999")
      .nullable(true)
      .transform((_, val) => (val === Number(val) ? val : null))
      .typeError("Required non-negative number"),
    quotaStartDate: Yup.date()
      .nullable()
      .when("quota", {
        is: (quota) => quota && !(+quota.toFixed(2) < 0.01),
        then: Yup.date().nullable().required("Required"),
      }),
    quotaEndDate: Yup.date()
      .nullable()
      .when("quota", {
        is: (quota) => quota && !(+quota.toFixed(2) < 0.01),
        then: Yup.date()
          .nullable()
          .required("Required")
          .min(
            Yup.ref("quotaStartDate"),
            "The date must be on or after the Quota Effective Date"
          ),
      })
      .when("licenseExpiryDate", {
        is: (licenseExpiryDate) => !!licenseExpiryDate,
        then: Yup.date()
          .nullable()
          .max(
            Yup.ref("licenseExpiryDate"),
            "The date must be on or before the License Expiry Date"
          ),
      }),
    tenantType: Yup.mixed().required("Required"),
    licenseExpiryDate: Yup.date()
      .nullable()
      .required("Required")
      .test(
        "min",
        "The date must be on or after the Quota Effective Date",
        function (value) {
          const { quota, quotaStartDate } = this.parent;
          if (!quota || +quota.toFixed(2) < 0.01 || !quotaStartDate)
            return true;
          return !isBefore(value, quotaStartDate);
        }
      ),

    ...(productId === PRODUCT_ID.saasAppsAndEndpoints
      ? {
          features: Yup.array().of(
            Yup.object().shape({
              attrs: Yup.object().when("isEnabled", {
                is: true,
                then: Yup.object().shape({
                  userCount: Yup.number()
                    .required("Required positive integer")
                    .min(1, "Required positive integer")
                    .max(999999, "The maximum user count allowed is 999999")
                    .test(
                      "decimalNotAllowed",
                      "User count should be an integer",
                      function (val) {
                        return Number.isInteger(val);
                      }
                    )
                    .nullable(),
                  ...(isPreservedUserCountVisible
                    ? {
                        preservedUserCount: Yup.number()

                          .when("userCount", {
                            is: (val) => val > 0,
                            then: Yup.number()
                              .test(
                                "userCount",
                                "Preserved user count should not be more than user count",
                                function (val) {
                                  const { userCount } = this.parent;
                                  return !val || userCount >= val;
                                }
                              )
                              .min(0, "Required positive integer")
                              .max(
                                999999,
                                "The maximum user count allowed is 999999"
                              )
                              .test(
                                "decimalNotAllowed",
                                "Preserved count should be an integer",
                                function (val) {
                                  return (
                                    val === undefined || Number.isInteger(val)
                                  );
                                }
                              )
                              .nullable(),
                          })
                          .nullable(),
                      }
                    : {}),
                }),
              }),
            })
          ),
        }
      : {}),
  });
};

export const isSKUEliteOrEnterprise = (servicePlan) =>
  (servicePlan?.products || []).some(
    (plan) =>
      plan.productID === PRODUCT_ID.saasAppsAndEndpoints &&
      (plan.edition === SKU.elite || plan.edition === SKU.enterprise)
  );

const TenantForm = ({
  closeModal,
  header,
  isOpen,
  isEdit,
  tenantId,
  customerId,
  productId,
  isHybridWorkLoadCardAction,
}) => {
  const dispatch = useDispatch();
  const [isPreservedUserCountVisible, setIsPreservedUserCountVisible] =
    useState(null);

  const { current: useMutation } = useRef(
    isEdit ? useUpdateTenantForm : useCreateTenantForm
  );

  const [defaultInitialValues, { mutate }] = useMutation(
    customerId,
    productId,
    tenantId
  );

  useEffect(() => {
    if (defaultInitialValues && isPreservedUserCountVisible === null) {
      setIsPreservedUserCountVisible(
        isSKUEliteOrEnterprise(defaultInitialValues.servicePlanID.servicePlan)
      );
    }
  }, [defaultInitialValues, isPreservedUserCountVisible]);

  const onSubmitHandler = useCallback(
    ({
      productID,
      customerId,
      servicePlanID,
      tenantType,
      storageRegions,
      quota,
      quotaStartDate,
      quotaEndDate,
      licenseExpiryDate,
      features,
    }) => {
      const defaultStorageRegions = defaultInitialValues?.storageRegions?.map(
        (region) => region.value
      );

      mutate(
        {
          productID,
          customerID: customerId,
          servicePlanID: servicePlanID.value,
          tenantType: tenantType.value,
          storageRegions: storageRegions
            .map(({ value }) => value)
            .filter((region) => {
              return !defaultStorageRegions.includes(region);
            }),
          quota: (quota && +quota.toFixed(2)) || 0,
          quotaStartDate: quotaStartDate && asUTC(quotaStartDate).toISOString(),
          quotaEndDate: quotaEndDate && asUTC(quotaEndDate).toISOString(),
          licenseExpiryDate: asUTC(endOfDay(licenseExpiryDate)).toISOString(),
          features:
            productID === PRODUCT_ID.saasAppsAndEndpoints
              ? (features || [])
                  .filter((feature) => feature.isEnabled)
                  .map(({ name, attrs }) => ({
                    name,
                    // Looks bad! But Patch API expects isEnabled mandatory but GET API do not provide this value.
                    isEnabled: true,
                    attrs: Object.keys(attrs)
                      .map((key) => {
                        if (
                          key === CAM_ATTRIBUTES.preservedUserCount &&
                          !isSKUEliteOrEnterprise(servicePlanID?.servicePlan)
                        ) {
                          return null;
                        }
                        return {
                          name: key,
                          value: attrs[key] || 0,
                        };
                      })
                      .filter(Boolean),
                  }))
              : [
                  {
                    name: CAM_FEATURES.hybridWorkloads,
                    // Looks bad! But Patch API expects isEnabled mandatory but GET API do not provide this value.
                    isEnabled: true,
                    attrs: [],
                  },
                ],
        },
        {
          onError: (e) => {
            const message =
              e?.message || e?.data?.message || genericErrorMessage;
            const type = e?.type || e?.data?.type || "danger";
            dispatch(
              showAlert({
                message: message,
                type: type,
              })
            );
          },
          onSuccess: (res) => {
            const successMessage = `${productIdToName[productId]} ${
              isEdit ? "updated" : "added"
            } successfully`;
            const alertMessage = {
              message: res.warning ? res.message : successMessage,
              type: res.warning ? "warning" : "success",
            };
            dispatch(showAlert(alertMessage));
            closeModal();
          },
        }
      );
    },
    [dispatch, isEdit, mutate, closeModal, productId, defaultInitialValues]
  );
  return (
    <>
      {defaultInitialValues && (
        <ModalForm
          className={styles.wizard}
          disableSubmitWhenInvalid
          submitButtonLabel="Save"
          closeModal={closeModal}
          withValidation
          header={header}
          isOpen={isOpen}
          size="lg"
          onSubmit={onSubmitHandler}
          initialValues={defaultInitialValues}
          validationSchema={(Yup) =>
            validationSchemaCreator({
              Yup,
              productId,
              isPreservedUserCountVisible,
            })
          }
          renderChildren={(formikProps) => {
            const supportedFeatures =
              productId === PRODUCT_ID.saasAppsAndEndpoints &&
              formikProps.values?.servicePlanID
                ? (
                    formikProps.values?.servicePlanID?.servicePlan?.products?.find(
                      (plan) =>
                        plan.productID === PRODUCT_ID.saasAppsAndEndpoints
                    )?.features || []
                  ).map((feature) => feature.name)
                : null;
            return (
              <FormContainer
                formikProps={formikProps}
                isEdit={isEdit}
                productId={productId}
                supportedFeatures={supportedFeatures}
                isHybridWorkLoadCardAction={isHybridWorkLoadCardAction}
                isPreservedUserCountVisible={isPreservedUserCountVisible}
                setIsPreservedUserCountVisible={setIsPreservedUserCountVisible}
              />
            );
          }}
        />
      )}
    </>
  );
};

TenantForm.defaultProps = {
  isEdit: false,
};

export default TenantForm;
