import { genericErrorMessage, PRODUCT_ID } from "consts";
import useCreateTenant from "queryHooks/useCreateTenant/useCreateTenant";
import useCustomerTenants from "queryHooks/useCustomerTenants/useCustomerTenants";
import useMsp from "queryHooks/useMsp/useMsp";
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "slices/alertsSlice";
import { asLocalTime } from "utils/timezone";

const useCreateTenantForm = (customerId, productId) => {
  const dispatch = useDispatch();

  const queryConfig = useMemo(
    () => ({
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          }),
        );
      },
    }),
    [dispatch],
  );

  const { data: tenants } = useCustomerTenants(
    { customerIDs: [customerId], filters: ["customerIDs"] },
    queryConfig,
  );

  const { data: mspInfo } = useMsp();

  const initialValues = useMemo(() => {
    if (tenants && mspInfo) {
      const sandboxCustomer = (tenants || []).find(
        (tenant) => tenant?.tenantType === 1,
      );

      const productInfo = (mspInfo.products || []).filter(
        (product) => product.productID === productId,
      )[0];
      return {
        customerID: customerId,
        licenseExpiryDate: sandboxCustomer
          ? asLocalTime(new Date(sandboxCustomer?.licenseExpiryDate))
          : null,
        quota: "",
        quotaEndDate: null,
        quotaStartDate: null,
        servicePlanID: "",
        storageRegions: [],
        tenantType: sandboxCustomer
          ? {
              label: "Sandbox",
              value: 1,
            }
          : null,
        productID: productId,
        ...(productId === PRODUCT_ID.saasAppsAndEndpoints
          ? {
              features: (productInfo.datasources || []).map((datasource) => ({
                name: datasource.name,
                isEnabled: true,
                attrs: {
                  userCount: "",
                  preservedUserCount: "",
                },
              })),
            }
          : {}),
      };
    }
  }, [productId, customerId, tenants, mspInfo]);
  const useMutation = useCreateTenant(customerId);

  return [initialValues, useMutation];
};

export default useCreateTenantForm;
