import { useQuery } from "react-query";

import { API_CUSTOMERS, QUERIES } from "consts";
import { useHttpClient } from "contexts/httpClient";

export default function useCustomerList({ isForOptions }, config) {
  const httpClient = useHttpClient();

  return useQuery(
    [QUERIES.customers, { isForOptions }],
    async () => {
      const res = await httpClient.get(API_CUSTOMERS, {
        params: {
          pageToken: "",
          pageSize: Number.MAX_SAFE_INTEGER,
        },
      });

      return {
        ...res,
        list: res?.customers,
      };
    },
    config
  );
}
