import React, { useCallback, useState, useMemo, useEffect } from "react";
import Row from "orion/lib/row";
import Col from "orion/lib/col";
import FormGroup from "orion/lib/formGroup";
import Icon from "orion/lib/icon";
import Input from "orion/lib/input";
import Button from "orion/lib/button";
import Modal from "orion/lib/modal";
import Tooltip from "orion/lib/tooltip";
import { getIn } from "formik";
import InfoGreyOutline from "@druva-apps/orion-icons/lib/InfoGreyOutline";
import WarningYellowOutline from "@druva-apps/orion-icons/lib/WarningYellowOutline";
import { endOfDay, isSameDay, addDays, max, startOfDay } from "date-fns";
import isNil from "lodash/isNil";

import {
  genericSelectTypes,
  M365_COMMERCIAL_MAX_EXPIRY_DAYS,
  PRODUCT_ID,
  dataSourceTypes,
  CAM_ATTRIBUTES,
  SUPPORT_PORTAL_URL,
  SKU,
  productIdToName,
} from "consts";
import Field from "components/Field/Field";
import useMsp from "queryHooks/useMsp/useMsp";
import InformationSVG from "assets/icons/information.svg";

import styles from "./TenantForm.module.scss";
import Card from "orion/lib/card/card";
import Heading from "orion/lib/heading/heading";
import {
  INITIAL_DATE,
  getMaxValidExpiryDate,
  isSKUEliteOrEnterprise,
} from "./TenantForm";
import { asLocalTime } from "utils/timezone";

const getEodMoment = (nativeDate) => {
  return endOfDay(nativeDate);
};
const getCurrentMomentForCurrentDate = (nativeDate) => {
  return isSameDay(nativeDate, new Date()) ? new Date() : nativeDate;
};

const useMSPExpiryDate = (productId, isSandboxCustomer) => {
  const { data: mspInfo } = useMsp();

  return useMemo(() => {
    let max = null;
    const mspExpiryDate = asLocalTime(new Date(mspInfo?.expiryDate));

    if (productId === PRODUCT_ID.saasAppsAndEndpoints) {
      const m365MaxDate = addDays(
        new Date(),
        M365_COMMERCIAL_MAX_EXPIRY_DAYS - 2,
      );
      max =
        isSandboxCustomer && mspExpiryDate.getTime() < m365MaxDate.getTime()
          ? mspExpiryDate
          : addDays(new Date(), M365_COMMERCIAL_MAX_EXPIRY_DAYS - 2);
    }
    return max;
  }, [productId, mspInfo, isSandboxCustomer]);
};

const FormContainer = ({
  formikProps,
  isEdit,
  isPreservedUserCountVisible,
  supportedFeatures,
  productId,
  isHybridWorkLoadCardAction,
  setIsPreservedUserCountVisible,
}) => {
  const [expiryMaxDate, setExpiryMaxDate] = useState(new Date());
  const [showWarningModal, setShowWarningModal] = useState(false);
  const initialDate = INITIAL_DATE[productId];
  const {
    tenantType: tenantTypeInitialValue,
    createdOn: createdOnInitialValue,
  } = formikProps?.initialValues || {};
  const isEvaluation = tenantTypeInitialValue?.value === 2;
  const isSandboxCustomer = tenantTypeInitialValue?.value === 1;

  const minExpiryDate = useMemo(() => {
    return productId === PRODUCT_ID.saasAppsAndEndpoints
      ? startOfDay(addDays(new Date(), 1))
      : startOfDay(new Date());
  }, [productId]);

  const mspExpiryDate = useMSPExpiryDate(productId, isSandboxCustomer);

  useEffect(() => {
    if (isEdit && isEvaluation) {
      const maxExtendDate = getMaxValidExpiryDate(
        new Date(createdOnInitialValue),
        productId,
      );
      setExpiryMaxDate(endOfDay(maxExtendDate || minExpiryDate));
    } else {
      setExpiryMaxDate(mspExpiryDate);
    }
  }, [
    createdOnInitialValue,
    initialDate,
    isEdit,
    isEvaluation,
    minExpiryDate,
    mspExpiryDate,
    productId,
  ]);

  const onTenantTypeChangeHandler = useCallback(
    ({ value }) => {
      if (value === 2) {
        // 2 == evaluation
        const maxDate = addDays(
          endOfDay(
            max([
              formikProps.values.quotaStartDate || initialDate,
              initialDate,
            ]),
          ),
          29,
        );

        if (
          !formikProps.values.quotaEndDate &&
          formikProps.values.quota &&
          formikProps.values.quotaStartDate
        ) {
          formikProps.setFieldValue("quotaEndDate", maxDate);
        }
        if (!formikProps.values.licenseExpiryDate) {
          formikProps.setFieldValue("licenseExpiryDate", maxDate);
        }
        setExpiryMaxDate(maxDate);
      } else {
        setExpiryMaxDate(mspExpiryDate);
      }
    },
    [formikProps, initialDate, mspExpiryDate],
  );

  const onQuotaEffectiveDateChangeHandler = useCallback(
    (nativeDate) => {
      if (formikProps.values.tenantType?.value === 2) {
        const maxDate = addDays(endOfDay(max([nativeDate, initialDate])), 29);
        setExpiryMaxDate(maxDate);
      }
      return getCurrentMomentForCurrentDate(nativeDate);
    },
    [formikProps.values.tenantType, initialDate],
  );

  const quotaChangeHandler = useCallback(
    (v) => {
      const numberValue = Number.parseFloat(v);
      if (Number.isNaN(numberValue) || numberValue === 0) {
        formikProps.setFieldValue("quotaEndDate", null);
        formikProps.setFieldValue("quotaStartDate", null);
      }
      return numberValue;
    },
    [formikProps],
  );

  const isQuotaInvalid = useMemo(
    () =>
      getIn(formikProps.touched, "quota") &&
      getIn(formikProps.errors, "quota") !== undefined,
    [formikProps.errors, formikProps.touched],
  );

  const checkIfInvalid = (index, key) => {
    return (
      getIn(formikProps.touched?.features?.[index]?.attrs, key) &&
      getIn(formikProps.errors?.features?.[index]?.attrs, key) !== undefined
    );
  };

  const disableQuotaDateRange = useMemo(() => {
    return (
      isNil(formikProps.values.quota) ||
      formikProps.values.quota <= 0 ||
      Number.isNaN(formikProps.values.quota)
    );
  }, [formikProps.values.quota]);

  useEffect(() => {
    formikProps.validateForm();
  }, []);

  const getIsBusinessPlan = useCallback(
    (plan) =>
      !!plan?.servicePlan?.products?.find(
        (product) =>
          product?.edition === SKU.business &&
          product?.features?.find(
            (feature) => feature?.name === productIdToName[1],
          ),
      ),
    [],
  );

  const getIsEliteOrEnterprisePlan = useCallback(
    (plan) =>
      !!plan?.servicePlan?.products?.find(
        (product) =>
          (product?.edition === SKU.elite ||
            product?.edition === SKU.enterprise) &&
          product?.features?.find(
            (feature) => feature?.name === productIdToName[1],
          ),
      ),
    [],
  );

  const handleShouldOnChangeBreak = useCallback(
    (plan) => {
      if (!isHybridWorkLoadCardAction) {
        return false;
      }

      const isInitialBusinessPlan = getIsBusinessPlan(
        formikProps?.initialValues?.servicePlanID,
      );

      if (isInitialBusinessPlan) {
        return false;
      }

      const isCurrentEliteOrEnterprisePlan = getIsEliteOrEnterprisePlan(
        formikProps?.values?.servicePlanID,
      );

      const isNextBusinessPlan = getIsBusinessPlan(plan);

      if (isCurrentEliteOrEnterprisePlan && isNextBusinessPlan) {
        setShowWarningModal(true);
        formikProps.setFieldValue(
          "servicePlanID",
          formikProps.values.servicePlanID,
        );
        return true;
      }
      return false;
    },
    [
      setShowWarningModal,
      formikProps,
      isHybridWorkLoadCardAction,
      getIsBusinessPlan,
      getIsEliteOrEnterprisePlan,
    ],
  );

  const renderWarningModal = useCallback(
    () => (
      <Modal
        header=""
        size="sm"
        dataTestId="downgrade-warning-modal"
        isOpen
        body={() => (
          <>
            <Row className="oui-mb-3">
              <Col sm={1}>
                <WarningYellowOutline />
              </Col>
              <Col sm={11} dataTestId="downgrade-warning-message">
                Select a different service plan, as downgrading to the business
                edition is not supported.
              </Col>
            </Row>
            <Row className="oui-mb-3">
              <Col sm={1}></Col>
              <Col sm={11} dataTestId="downgrade-support-message">
                Contact
                <a href={SUPPORT_PORTAL_URL} target="_blank" rel="noreferrer">
                  &nbsp;Support&nbsp;
                </a>
                for assistance.
              </Col>
            </Row>
          </>
        )}
        footer={() => (
          <Button
            type="primary"
            label="Ok"
            dataTestId="downgrade-modal-button-ok"
            onClick={() => setShowWarningModal(false)}
          />
        )}
      />
    ),
    [],
  );

  return (
    <>
      {showWarningModal && renderWarningModal()}
      <Card
        title="Details"
        body={() => (
          <Row>
            <Col className="oui-px-4">
              <Field
                type={genericSelectTypes.servicePlans}
                name="servicePlanID"
                label="Service Plan"
                placeholder="Select"
                dataTestId="msp-m365-service-plan-txt"
                formikProps={formikProps}
                inputContainerClassName={styles.longInput}
                products={[productId]}
                shouldOnChangeBreak={handleShouldOnChangeBreak}
                menuPosition="fixed"
                onChange={(plan) => {
                  formikProps.setFieldValue("servicePlanID", plan);
                  if (productId === PRODUCT_ID.saasAppsAndEndpoints) {
                    const features = (plan?.servicePlan?.products || []).find(
                      (product) => product.productID === productId,
                    )?.features;
                    formikProps.setFieldValue(
                      "features",
                      (formikProps.values?.features || []).map((feature) => ({
                        ...feature,
                        isEnabled: (features || []).some(
                          (ftr) => ftr.name === feature.name && ftr.isEnabled,
                        ),
                      })),
                    );
                    setIsPreservedUserCountVisible(
                      isSKUEliteOrEnterprise(plan?.servicePlan),
                    );
                  }
                }}
              />
              <Field
                type={genericSelectTypes.tenantTypes}
                name="tenantType"
                label="Tenant Type"
                placeholder="Select"
                dataTestId="msp-add-customer-tenant-type-ddl"
                formikProps={formikProps}
                disabled={
                  formikProps.initialValues.tenantType?.value === 1 ||
                  (isEdit && formikProps.initialValues.tenantType?.value === 3)
                }
                onChange={onTenantTypeChangeHandler}
                inputContainerClassName={styles.longInput}
              />
              <Field
                type="date"
                name="licenseExpiryDate"
                label="License Expiry Date"
                placeholder="Select"
                labelTooltip={
                  formikProps.values.tenantType?.value === 2
                    ? "License cannot be extended beyond 60 days from tenant creation date."
                    : null
                }
                dataTestId="msp-add-customer-license-expiry-date-dtp"
                formikProps={formikProps}
                minDate={minExpiryDate}
                interceptor={getEodMoment}
                maxDate={expiryMaxDate}
                disabled={isSandboxCustomer}
                inputContainerClassName={styles.dateInput}
              />
              <Field
                type={genericSelectTypes.storageRegions}
                isMulti
                name="storageRegions"
                label="Storage Region"
                placeholder="Select Region"
                dataTestId="msp-add-customer-storage-region-ddl"
                formikProps={formikProps}
                fixedInitialValues
                inputContainerClassName={styles.longInput}
                products={[productId]}
                menuPosition="fixed"
              />
              <FormGroup row className="oui-mb-0">
                <Col sm={3}>
                  <span className="oui-text-muted">Quota</span>
                  <Tooltip
                    maxWidth={300}
                    placement="bottom"
                    text="Quota is measured in Consumption Units"
                  >
                    <InfoGreyOutline muted={false} className="oui-ml-2" />
                  </Tooltip>
                </Col>
                <Col
                  sm={2}
                  className={`oui-mt-auto ${
                    isQuotaInvalid ? "oui-mb-1" : "oui-mb-4"
                  } ${styles.quotaInput}`}
                >
                  <Input
                    type="number"
                    className={styles.numberField}
                    name="quota"
                    id="quota"
                    validationProps={formikProps}
                    isInvalid={isQuotaInvalid}
                    min={0}
                    max={9999999999}
                    data-testid="msp-m365-quota-size-ddl"
                    onChange={(e) => {
                      formikProps.setFieldValue(
                        "quota",
                        quotaChangeHandler(e.target.value),
                      );
                    }}
                    onBlur={(e) => {
                      const numberValue = Number.parseInt(e.target.value, 10);
                      if (Number.isNaN(numberValue)) {
                        formikProps.setFieldValue("quota", 0);
                      }
                    }}
                  />
                </Col>
              </FormGroup>
              <Field
                type="date"
                name="quotaStartDate"
                label="Quota Effective Date"
                placeholder="Select"
                dataTestId="msp-add-customer-quota-start-date-dtp"
                formikProps={formikProps}
                minDate={minExpiryDate}
                maxDate={expiryMaxDate}
                interceptor={onQuotaEffectiveDateChangeHandler}
                inputContainerClassName={styles.dateInput}
                disabled={disableQuotaDateRange}
              />
              <Field
                type="date"
                name="quotaEndDate"
                label="Quota End Date"
                placeholder="Select"
                dataTestId="msp-add-customer-quota-end-date-dtp"
                formikProps={formikProps}
                minDate={
                  productId === PRODUCT_ID.saasAppsAndEndpoints
                    ? addDays(new Date(), 1)
                    : new Date()
                }
                interceptor={getEodMoment}
                maxDate={expiryMaxDate}
                inputContainerClassName={styles.dateInput}
                disabled={disableQuotaDateRange}
              />
            </Col>
          </Row>
        )}
      />
      {productId === PRODUCT_ID.saasAppsAndEndpoints && (
        <Card
          className="oui-mt-3"
          body={() => {
            return (
              <>
                {(formikProps.initialValues?.features || []).map(
                  (feature, index) => (
                    <React.Fragment key={feature.name}>
                      <Row>
                        <Col>
                          <Heading text={dataSourceTypes[feature.name]} />
                        </Col>
                      </Row>
                      {!supportedFeatures ||
                      (supportedFeatures || []).includes(feature.name) ? (
                        <FormGroup row className="oui-mb-0">
                          <Col sm={3}>
                            <span className="oui-text-muted">User Count</span>
                            <Tooltip
                              maxWidth={300}
                              placement="bottom"
                              text="This is the maximum number of users you have setup. Consumption will be for only the number of users that have been setup for backup"
                            >
                              <Icon src={InformationSVG} className="oui-ml-2" />
                            </Tooltip>
                            <span
                              className={`oui-text-muted ${styles.labelSubtext}`}
                            >
                              (Max Limit)
                            </span>
                          </Col>
                          <Col
                            sm={3}
                            className={`oui-mt-auto ${
                              checkIfInvalid(index, CAM_ATTRIBUTES.userCount)
                                ? "oui-mb-1"
                                : "oui-mb-4"
                            } ${styles.quotaInput}`}
                          >
                            <Input
                              type="number"
                              className={styles.numberField}
                              name={`features[${index}].attrs.userCount`}
                              id={`features[${index}].attrs.userCount`}
                              validationProps={formikProps}
                              isInvalid={checkIfInvalid(
                                index,
                                CAM_ATTRIBUTES.userCount,
                              )}
                              min={0}
                              max={9999999999}
                              data-testid="msp-m365-user-count-size-ddl"
                            />
                          </Col>
                          {isPreservedUserCountVisible && (
                            <>
                              <Col sm={3}>
                                <span className="oui-text-muted">
                                  Preserved User Count
                                </span>
                                <Tooltip
                                  maxWidth={300}
                                  placement="bottom"
                                  text={`Enter the value for the number of preserved users. Druva ${
                                    dataSourceTypes[feature.name]
                                  } automatically provisions additional preserved users equivalent to 10% of user count over and above the defined value provided.`}
                                >
                                  <Icon
                                    src={InformationSVG}
                                    className="oui-ml-2"
                                  />
                                </Tooltip>
                              </Col>
                              <Col
                                sm={3}
                                className={`oui-mt-auto ${
                                  checkIfInvalid(
                                    index,
                                    CAM_ATTRIBUTES.preservedUserCount,
                                  )
                                    ? "oui-mb-1"
                                    : "oui-mb-4"
                                } ${styles.quotaInput}`}
                              >
                                <Input
                                  type="number"
                                  className={styles.numberField}
                                  name={`features[${index}].attrs.preservedUserCount`}
                                  id={`features[${index}].attrs.preservedUserCount`}
                                  validationProps={formikProps}
                                  isInvalid={checkIfInvalid(
                                    index,
                                    CAM_ATTRIBUTES.preservedUserCount,
                                  )}
                                  min={0}
                                  max={9999999999}
                                  data-testid="msp-m365-preserved-user-count-size-ddl"
                                />
                              </Col>
                            </>
                          )}
                        </FormGroup>
                      ) : (
                        <Row>
                          <Col className="oui-d-flex">
                            <WarningYellowOutline
                              muted={false}
                              className="oui-mr-2"
                            />
                            <div className="font-12 oui-text-muted">
                              Not supported by selected Service Plan
                            </div>
                          </Col>
                        </Row>
                      )}
                    </React.Fragment>
                  ),
                )}
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default FormContainer;
