import { useQuery } from "react-query";
import useServicePlanWorkloadList from "queryHooks/useServicePlanWorkloadList/useServicePlanWorkloadList";
import { productIdToName, QUERIES } from "consts";

export default function useMspProducts(config) {
  const { data: workloads } = useServicePlanWorkloadList();

  return useQuery(
    [QUERIES.mspProductList],
    () => {
      const products = workloads.map(({ productID }) => ({
        id: productID,
        name: productIdToName[productID],
      }));
      return {
        list: products,
      };
    },
    { enabled: workloads?.length > 0, ...config }
  );
}
